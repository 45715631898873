// Render a Contentful reference with a matching component

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-intl"
import { colors } from "../theme"
import Button from "./button"

const Wrapper = styled.div`
    position: fixed;
    left: 0;
    top: 50%;
    transform-origin: top left;
    transform: rotate(-90deg) translateX(-50%);
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    font-size: 0.7em;
    z-index: 2;
    @media (min-width: 769px) {
      font-size: 1em;
    }
`

const InternalItem = styled(Link)`
    padding: 0.5em 1em;
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    position: relative;
    transition: transform 200ms ease-in-out;
    transform: translateY(0%);
    letter-spacing: 0.1em;
    text-decoration: none;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${colors.mustikka};
        z-index: -1;
        box-shadow: -5px 2px 5px rgba(0,0,0,0.3);
        transform: perspective(100px) scaleY(1.8) translateY(4%) translateZ(-7px) rotateX(-45deg);
    }
    &:first-child:before {
        background: ${colors.kaisla};
    }
    &:hover {
        transform: translateY(10%);
    }
    span {
      transform: perspective(100px) translateZ(100px);
      position: relative;
      z-index: 0;
    }
`

const ExternalItem = styled.a`
    padding: 0.5em 1em;
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    position: relative;
    transition: transform 200ms ease-in-out;
    transform: translateY(0%);
    letter-spacing: 0.1em;
    text-decoration: none;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${colors.mustikka};
        z-index: -1;
        box-shadow: -5px 2px 5px rgba(0,0,0,0.3);
        transform: perspective(100px) scaleY(1.8) translateY(4%) translateZ(-7px) rotateX(-45deg);
    }
    &:first-child:before {
        background: ${colors.kaisla};
    }
    &:hover {
        transform: translateY(10%);
    }
    span {
      transform: perspective(100px) translateZ(100px);
      position: relative;
      z-index: 0;
    }
`

export default function({buttons}) {
  if(!buttons || buttons.length === 0) {
    return null
  }

  return <Wrapper>
      {buttons.map((buttonData, i) => {
        const Component = (buttonData.linkUrl || buttonData.otherTarget === "Experience booking")
          ? ExternalItem : InternalItem
        return <Button key={i} {...buttonData} wrapperComponent={Component} />
      })}
  </Wrapper>
}
