import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Link, injectIntl } from "gatsby-plugin-intl"
import ArrowIcon from "./arrow-icon"
import Popup from "./popup"
import { colors } from "../theme"
import { TranslationContext } from "../translate"

export const StyledInternalButton = styled(Link)`
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  font-size: 0.9em;
  line-height: 1em;
  padding: 0.6em 1.2em;
  font-weight: 400 !important;
  font-style: normal !important;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  &:hover {
    background: ${({ color }) => color};
    color: #fff;
  }
`

export const StyledInternalPrimaryButton = styled(StyledInternalButton)`
  background: ${({ color }) => color};
  color: #fff !important;
  &:hover {
    color: ${({ color }) => color} !important;
    background: #fff;
  }
`

export const StyledExternalButton = styled.a`
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  font-size: 0.9em;
  line-height: 1em;
  padding: 0.6em 1.2em;
  font-weight: 400 !important;
  font-style: normal !important;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  &:hover {
    background: ${({ color }) => color};
    color: #fff;
  }
`

export const StyledExternalPrimaryButton = styled(StyledExternalButton)`
  background: ${({ color }) => color};
  color: #fff;
  &:hover {
    color: ${({ color }) => color} !important;
    background: #fff;
  }
`

export const HyperlinkButton = styled(StyledExternalPrimaryButton)`
  font-size: 1rem;
  display: inline-block;
  color: #fff !important;
  background: ${colors.kaisla};
  font-weight: normal !important;
  &:hover {
    color: ${colors.kaisla} !important;
    background: #fff !important;
  }
  & * {
    font-weight: normal !important;
  }
`

export function getButtonProps({
  intl,
  linkUrl,
  targetPage,
  targetSection,
  otherTarget,
}) {
  const isFinnish = intl.locale === "fi"
  let props = {}
  if (targetPage || targetSection) {
    props.to = "/"
    if (targetPage) {
      props.to += targetPage.slug
    }
    if (targetSection) {
      props.to += "#" + targetSection.identifier
    }
  } else if (linkUrl) {
    props.href = linkUrl
  } else if (otherTarget === "Room booking") {
    props.className = "distributor"
    props.onClick = e => e.preventDefault()
  } else if (otherTarget === "Experience booking") {
    props.href = isFinnish
    ? "https://fareharbor.com/embeds/book/haltialakelodge/?full-items=yes&flow=533137&language=fi"
    : "https://fareharbor.com/embeds/book/haltialakelodge/?full-items=yes&flow=533137&language=en"
    props.target = "_blank"
    props.rel = "noopener norefferer"
  }
  return props
}

const Button = (props) => {
  const {
    title,
    color,
    primary,
    linkUrl,
    style,
    otherTarget,
    targetPopup,
    wrapperComponent,
    onClick,
  } = props

  const Component =
    wrapperComponent ||
    (linkUrl || otherTarget === "Experience booking"
      ? primary
        ? StyledExternalPrimaryButton
        : StyledExternalButton
      : primary
      ? StyledInternalPrimaryButton
      : StyledInternalButton)

  const [modalOpen, setModalOpen] = useState(false)
  const buttonProps = getButtonProps(props)

  if (targetPopup) {
    buttonProps.onClick = e => {
      e.preventDefault()
      setModalOpen(true)
    }
  }
  if (onClick) {
    buttonProps.onClick = onClick
  }

  return (
    <>
      <TranslationContext.Consumer>
        {(t) =>
          <Component color={color || colors.pakuri} {...buttonProps} style={style}>
            {t(title)}
            {/* !wrapperComponent && <ArrowIcon />*/}
          </Component>
        }
      </TranslationContext.Consumer>
      {targetPopup && (
        <Popup {...targetPopup} isOpen={modalOpen} setOpen={setModalOpen} />
      )}
    </>
  )
}

export default injectIntl(Button)

export const query = graphql`
  fragment Button on ContentfulButton {
    title
    linkUrl
    targetPage {
      ... on ContentfulPage {
        slug
      }
    }
    targetSection {
      ... on ContentfulHomeSection {
        identifier
      }
      ... on ContentfulPageSection {
        identifier
      }
    }
    targetPopup {
      ...Popup
    }
    otherTarget
  }
`
