import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { contentOptions } from "../rich-text"
import { colors } from "../theme"
import { TranslationContext } from "../translate"


const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    white-space: pre-wrap;
    h1, h3, .lead-paragraph {
        color: ${({color}) => color ? colors[color] : "inherit"};
    }
    .lead-paragraph {
        font-style: italic;
        font-size: 1.2em;
        text-align: center;
        margin: 3em 0;
        line-height: 1.5em;
    }
    h1 {
        font-size: 3em;
        font-weight: 200;
        margin-top: 0.5em;
        margin-bottom: 0.2em;
        text-align: center;
        text-transform: uppercase;
        span:last-child {
            font-weight: 900;
        }
        @media (min-width: 769px) {
        font-size: 5em;
        }
    }
    h2 {
        font-size: 1.8em;
        font-weight: 400 !important;
        color: #969592;
        text-align: ${({align}) => align} !important;
        margin-top: 0.6em;
    }
    h3 {
        font-size: 1.5em;
        padding: 0.5em 0;
        border-bottom: 3px solid currentColor;
        font-weight: bold;
    }
    h4 {
        font-size: 1.25em;
        font-weight: bold;
    }
    h5 {
        font-size: 1.1em;
        font-weight: bold;
    }
    h6 {
        font-size: 1em;
        font-weight: bold;
    }
    a {
        font-weight: bold;
        font-style: italic;
        text-decoration: underline;
        color: ${colors.kaisla};
        cursor: pointer;
        &:hover {
        color: ${colors.havu};
        }
    }
    blockquote {
        color: ${colors.kaisla};
        border-left: 0.5em solid ${colors.kaisla};
        margin: 0.5em 0;
        padding: 0.5em 1em;
        font-size: 1.2em;
        font-weight: bold;
        font-style: italic;
    }
    p {
        line-height: 1.5em;
    }
    h3 {
        color: ${colors.kaisla} !important;
    }
    p {
        text-align: ${({align}) => align};
        line-height: 1.75em !important;
    }
    p:first-child {
      margin-top: 0;
      font-style: italic;
      color: ${colors.kaisla};
    }
    ul {
        background: none !important;
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        li {
            p {
                font-style: normal !important;
                color: ${colors.pakuri} !important;
                text-align: left !important;
            }
            box-sizing: border-box;
            margin: 0 1.2em;
            ::marker {
                color: ${colors.kaisla};
            }
        }
    }
    hr {
        margin: 0.5em 0;
    }
    ${({columnedLists}) => columnedLists ? `@media (min-width: 769px) {
        ul {
            flex-direction: row;
            flex-wrap: wrap;
            li {
                width: calc(33% - 2.4em);
            }
        }
    ` : ""}}
`

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    @media (min-width: 769px) {
        flex-direction: row;
    }
`

const BoxOuter = styled(Outer)`
  background: ${colors.background.Light};
  ${Wrapper} {
    padding: 2em;
  }
`

const Image = styled.img`
  object-fit: cover;
  max-height: 16em;
  @media (min-width: 769px) {
    width: 33.33%;
    max-height: 100%;
  }
`

export default function({content, align, image, type, columnedLists}) {
  const OuterComponent = type === "box" ? BoxOuter : Outer
  return <TranslationContext.Consumer>{(t) =>
    <OuterComponent>
        <Wrapper align={align || "left"} columnedLists={columnedLists}>
            {content && renderRichText(t(content, true), contentOptions)}
        </Wrapper>
        {image && <Image src={image.localFile.childImageSharp.fixed.src} />}
    </OuterComponent>
  }</TranslationContext.Consumer>
}

export const query = graphql`
  fragment TextBlock on ContentfulTextBlock {
    content {
        raw
        references {
            __typename
            ... on ContentfulAsset {
              contentful_id
              localFile {
                publicURL
              }
              localFile {
                childImageSharp {
                  fixed(width: 1440) {
                    src
                  }
                }
              }
            }
          }
    }
    image {
      localFile {
        childImageSharp {
          fixed(width: 600) {
            src
          }
        }
      }
    }
    type
    align
    columnedLists
  }
`
