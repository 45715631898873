let id = 'mews-distributor-script'
let distributor = null

let localeMapping = {
    "fi": "fi-FI",
    "de": "de-DE",
    "fr": "fr-FR",
    "es": "es-ES",
    "ja": "ja-JP",
    "ko": "ko-KR"
}

export function initDistributor(language) {
    if(typeof document !== "undefined" && document.getElementById(id) === null) {
        let script = document.createElement('script')
        script.setAttribute('src', 'https://www.mews.li/distributor/distributor.min.js')
        script.setAttribute('id', id)
        document.body.appendChild(script)
        script.onload = () => {
            window.Mews.Distributor({
                configurationIds: [
                    '1ec7f703-b7b0-41b5-af5a-ad08007e050a'
                ],
                openElements: '.distributor'
            },
            function (dstr) {
                // Start of August 2021 or current date whichever is later:
                distributor = dstr
                distributor.setLanguageCode(localeMapping[language] || "en-US")
                distributor.setStartDate(new Date(Math.max(new Date(), new Date(2021, 7, 3))))
            })
        }
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getDistributor = async () => {
    if(!distributor) {
        await sleep(1000)
        return await getDistributor()
    }
    else {
        return distributor
    }
}