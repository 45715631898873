import React from "react"
import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import { Link } from "gatsby-plugin-intl"
import styled from "styled-components"
import { colors } from "./theme"
import get from "lodash/get"
import { HyperlinkButton } from "./components/button"

const EmbeddedContentImage = styled("img")`
  width: 100%;
  display: block;
`

const ContentVideoWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
`

const ContentVideo = styled("video")`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${colors.pakuri};
`

const BUTTON_LINK_WRAPPERS = ["[", "]"]

export const contentOptions = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return <Link to={"/" + node.data.target.slug}>{children}</Link>
      },
      /*[INLINES.HYPERLINK]: (node, children) => {
        let linkText = get(node, "content[0].value")
        if(linkText && linkText.length > 2 && linkText.charAt(0) === BUTTON_LINK_WRAPPERS[0] && linkText.substr(-1) === BUTTON_LINK_WRAPPERS[1]) {
          return <HyperlinkButton href={node.data.uri}>{children}</HyperlinkButton>
        }
        return <a href={"/" + node.data.uri}>{children}</a>
      },*/
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const target = node.data.target
        if (!target) {
          return null
        }
        if (target.localFile && target.localFile.childImageSharp) {
          // the asset is an image
          return <EmbeddedContentImage src={target.localFile.childImageSharp.fixed.src} />
        } else if (target.localFile) {
          // not an image, probably video then ¯\_(ツ)_/¯
          return (
            <ContentVideoWrapper>
              <ContentVideo controls>
                <source src={target.localFile.publicURL} />
              </ContentVideo>
            </ContentVideoWrapper>
          )
        }
        return null
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return <a href={get(node, "data.target.localFile.publicURL")} target="_blank" rel="noopener noreferrer">{children}</a>
      },
    },
}
