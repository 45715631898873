import React from "react"
import { StaticQuery, graphql } from "gatsby"
import find from "lodash/find"
import keyBy from "lodash/keyBy"
import mapValues from "lodash/mapValues"
import pickBy from "lodash/pickBy"
import traverse from "traverse"
import debounce from "lodash/debounce"

export const TranslationContext = React.createContext(null);

// For exporting missing translations
const PRINT_NOT_FOUND_KEYS = false;


const notFoundKeys = []
const printNotFoundKeys = debounce(() => {
    console.log(notFoundKeys.join("\n"))
}, 2000)

const handleNotFound = (key) => {
    if(PRINT_NOT_FOUND_KEYS) {
        let skey = "\"" + key + "\""
        if(!notFoundKeys.includes(skey)) {
            notFoundKeys.push(skey)
            printNotFoundKeys()
        }
    }
}

const sanitize = (str) => {
    if(str) {
        str = str.replace(/[^a-z0-9\.,_-]/gim, "")
        return str.trim().toLowerCase()
    }
    return null
}

const getTranslationFunction = (dictionary) => {
    return (key, rich = false) => {
        //console.log("key", key)
        if(key.length < 2) {
            return key
        }
        if (typeof key === 'string' || key instanceof String) {
            let sKey = sanitize(key)
            //console.log("attempting to translate", sKey)
            if(dictionary[sKey]) {
                //console.log("translating", sKey)
                return dictionary[sKey]
            }
            handleNotFound(key)
            return key
        }
        else if (rich && key.raw) {
            let jsonRaw = JSON.parse(key.raw)
            //console.log("jsonRaw", jsonRaw)
            traverse(jsonRaw).forEach(function (x) {
                if (this.key === "value") {
                    let sx = sanitize(x)
                    //console.log("attempting to translate", sx)
                    if(dictionary[sx]) {
                        //console.log("translating", sx)
                        this.update(dictionary[sx])
                    } else {
                        handleNotFound(x)
                    }
                }
            });
            return {
                ...key,
                raw: JSON.stringify(jsonRaw)
            }
        }
        handleNotFound(key)
        return key
    }
}

const getKey = (o) => {
    //console.log("sanitized key", sanitize(o.key))
    return sanitize(o.key)
}

export const TranslationContextProvider = ({locale, children}) => {
    return <StaticQuery
        query={graphql`
            query MyQuery {
                allTranslation {
                    nodes {
                        translations {
                            key
                            value
                        }
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
        
        `}
        render={(translationData) => {
            const trData = translationData.allTranslation.nodes;
            const localeData = find(trData, (d) => d.parent.name === locale)
            const translations = localeData && localeData.translations || []
            const dictionary = pickBy({
                ...mapValues(keyBy(translations, getKey), 'value'),
                //...mapValues(keyBy(translations, 'key'), 'value')
            }, (_, k) => k.length > 1)
            //console.log("trData", trData)
            //console.log("translations", translations.filter((t) => !t.value))
            //console.log("dictionary", dictionary)
            return (
                <TranslationContext.Provider value={getTranslationFunction(dictionary)}>
                    {children}
                </TranslationContext.Provider>
            )
        }}
    />
}
